import React from 'react'
import { graphql, Link } from 'gatsby'
import { css } from 'linaria'
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../layouts'
import Pager from "../components/Pager"
const moment = require('moment');

const onePost = css`
  display: block;
  text-decoration: none;
  width: calc((100% - 20px) / 3);
  margin-right: 10px;
  position: relative;
  
  &:nth-of-type(3n) {
    margin-right: 0;
  }
  
  @media (max-width:1000px) {
    width: 100%;
    margin-right: 0;
  }
  
  img {
    filter: blur(5px) sepia(60%);
    
    @media (max-width:1000px) {
      filter: none;
    }
  }
  
  &:hover {
    img {
      filter: none;
    }
    
    h3 {
      color: #BA2636;
    }
  }
`

const flexIndex = css`
  display: flex;
  flex-wrap: wrap;
`

const postTime = css`
  position: absolute;
  top: 5px;
  padding: 2px;
  background-color: #FDF2F5;
`

const tagArea = css`
  position: absolute;
  top: 2.5em;
`

const oneTag = css`
  background-color: #BA2636;
  color: #fff;
  padding: 2px;
  &:before {
    content: '#';
  }
  
  &:after {
    content: ' ';
  }
`

const BlogIndex = ({ data, pageContext }) => {
  const { edges } = data.allMarkdownRemark
  return (
    <Layout>
      <div className="wrapper">
        <div className={flexIndex}>
          {edges.map(({ node }) => (
            <Link to={node.frontmatter.path} className={onePost}>
              <GatsbyImage
                image={node.frontmatter.cover.childImageSharp.gatsbyImageData}
                alt={node.frontmatter.title} />
              <h3>{node.frontmatter.title}</h3>
              
              <div className={postTime}><time dateTime={node.frontmatter.date}>{moment(node.frontmatter.date).format("YYYY年MM月DD日")}</time></div>
              
              <div className={tagArea}>
                {node.frontmatter.tags.map(tag =>
                  <span className={oneTag}>
                    {tag}
                  </span>
                )}
              </div>
            </Link>
          ))}
        </div>
        <Pager pageContext={pageContext} />
      </div>
    </Layout>
  );
}

export default BlogIndex

export const pageQuery = graphql`query ($skip: Int!, $limit: Int!) {
  top: file(absolutePath: {regex: "/top.jpg/"}) {
    ...fluidImage
  }
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(
    sort: {fields: [frontmatter___date], order: DESC}
    skip: $skip
    limit: $limit
  ) {
    edges {
      node {
        excerpt(truncate: true)
        frontmatter {
          date
          path
          title
          tags
          cover {
            childImageSharp {
              gatsbyImageData(quality: 90, placeholder: TRACED_SVG, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`
export const fluidImage = graphql`fragment fluidImage on File {
  childImageSharp {
    gatsbyImageData(width: 630, placeholder: NONE, layout: CONSTRAINED)
    fixed(width: 300) {
      ...GatsbyImageSharpFixed_withWebp
    }
  }
}
`